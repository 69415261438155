import React, { useEffect, useState } from "react";
import "./user-profile-popup.scss";

import { ReactComponent as Logo } from "../../logo.svg";
import { AiOutlineSchedule, AiOutlineUser } from "react-icons/ai";
import { IoLocationSharp, IoSchoolSharp } from "react-icons/io5";
import { BsStars, BsFillBriefcaseFill } from "react-icons/bs";
import { ImHappy } from "react-icons/im";
import { MdHomeWork } from "react-icons/md";
import { useSelector } from "react-redux";

import NoMoreProfile from "./no-more-profile.component";
import ScheduleCallPopup from "./schedule-call-popup";
import { communicationTypeOptionList, defaultAvailabilityWindow, defaultTimeZone, durationOptionList } from "../../pages/schedule/settings/settings.constant";

const UserProfilePopup = ({
  popupVisible,
  sendDatatoParent,
  profile,
  NextProfileClickToParent = null,
  loading,
  nextbtnvisible = true,
  titleVisible = true,
  otherClassName = null,
  showCancelButton = true,
  requestFromMyConnection = false,
}) => {

  console.log({profile})

  //console.log(JSON.parse(profile["schedule-meeting-settings"]));

  const firstname = useSelector((state) => state.user.firstName);
  const userCredit = useSelector((state) => state.user.mycredits);
  const [schedulePopupVisible, setSchedulePopupVisible] = useState(false);
  const [callScheduled, setCallScheduled] = useState(
    profile?.call_scheduled || false
  );
  const [ showOutofCreditwindow, setShowOutofCreditwindow] = useState(false);
  const [scheduleSettings, setScheduleSettings] = useState(null)

  const handleShowOutofCreditWindow = () => {
    setShowOutofCreditwindow(!showOutofCreditwindow)
  }

  let profiledata = profile;
  
  // {
  //   ...profile
  //   "schedule-meeting-settings": profile?.["schedule-meeting-settings"]
  //     ? JSON.parse(profile?.["schedule-meeting-settings"])
  //     : profile?.["schedule-meeting-settings"],
  // };

  useEffect( () => {
    if (profile?.["schedule-meeting-settings"] && typeof profile?.["schedule-meeting-settings"] !== 'object') {
      setScheduleSettings(JSON.parse(profile?.["schedule-meeting-settings"]));
    } else {
      setScheduleSettings(profile?.["schedule-meeting-settings"]);
    }
  }, [profile])

  const sendDataToParentSchedule = (visiblebool) => {
    setSchedulePopupVisible(visiblebool);
  };

  const sendDataToParentNoMoreData = (visiblebool) => {
    sendDatatoParent(visiblebool);
    profiledata = "";
  };

  const callScheduledFunc = (callScheduled) => {
    setCallScheduled(callScheduled);
  };

  const userProfilePlaceholder = (
    <React.Fragment>
      <div className="col-12">
        <div className="user_popup border-bottom-0">
          <div className="row py-4">
            <div className="col-12 col-md-4">
              <div className="user_popup_thumbnail">
                <Logo />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="row">
                <div className="col-12 pb-2 pb-md-4">
                  <h3 className="user_popup_name">Susan 3For3networking</h3>

                  <p className="user_popup_designation">Networking Expert</p>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="user_popup_list">
                    <li className="user_popup_list_item">
                      <span>
                        <IoLocationSharp size="1.5em" />
                      </span>
                      <span>Columbus, Ohio</span>
                    </li>

                    <li className="user_popup_list_item">
                      <span>
                        <MdHomeWork size="1.5em" />
                      </span>
                      <span>Industry</span>
                    </li>
                    <li className="user_popup_list_item">
                      <span>
                        <IoSchoolSharp size="1.5em" />
                      </span>
                      <span>School</span>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="user_popup_list">
                    <li className="user_popup_list_item">
                      <span>
                        <ImHappy size="1.5em" />
                      </span>
                      <span>Digital Marketing</span>
                    </li>

                    <li className="user_popup_list_item">
                      <span>
                        <BsStars size="1.5em" />
                      </span>
                      <span>Classification</span>
                    </li>
                    <li className="user_popup_list_item">
                      <span>
                        <BsFillBriefcaseFill size="1.5em" />
                      </span>
                      <span>Company</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="user_popup border-0">
          <div className="row">
            <div className="col-12 col-md-6 pr-md-0">
              <button className="user_popup_schedulecall_btn">
                <AiOutlineSchedule size="1.8em" /> Schedule a Call
              </button>
            </div>
            <div className="col-12 col-md-6 pl-md-0">
              <button className="user_popup_schedulecall_btn next_profile">
                <AiOutlineUser size="1.8em" /> Next Profile
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );


  let userProfileUI = "";

  if(userCredit >= 0) {
    userProfileUI = (
      <React.Fragment>
        <div className="col-12">
          <div className="user_popup border-bottom-0">
            <div className="row py-4 px-md-4">
              <div className="col-12 col-lg-4">
                {profiledata?.profile_picture ? (
                  <div
                    className="user_popup_thumbnail mb-4 mb-lg-0"
                    style={{
                      backgroundImage: `url(${profiledata?.profile_picture})`,
                    }}
                  ></div>
                ) : (
                  <div className="user_popup_thumbnail mb-4 mb-lg-0">
                    <Logo fill="#3861b3" />
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-8 px-5 px-lg-0">
                <div className="row">
                  <div className="col-12 pb-2 pb-md-4">
                    <h3 className="user_popup_name">
                      {profiledata?.first_name && profiledata?.last_name
                        ? `${profiledata?.first_name} ${profiledata?.last_name}`
                        : ""}
                    </h3>
                    {profiledata?.headline && (
                      <p className="user_popup_designation">
                        {profiledata?.headline}
                      </p>
                    )}
                  </div>
                  <div className="col-12 col-md-6">
                    <ul className="user_popup_list mb-3 mb-sm-0">
                      {(profiledata?.city || profiledata?.state) && (
                        <li className="user_popup_list_item">
                          <span>
                            <IoLocationSharp size="1.5em" />
                          </span>
                          <span>{`${profiledata?.city}${
                            profiledata?.state ? ", " + profiledata?.state : ""
                          }`}</span>
                        </li>
                      )}
                      {profiledata?.industry && (
                        <li className="user_popup_list_item">
                          <span>
                            <MdHomeWork size="1.5em" />
                          </span>
                          <span>{profiledata?.industry}</span>
                        </li>
                      )}
                      {profiledata?.university && (
                        <li className="user_popup_list_item">
                          <span>
                            <IoSchoolSharp size="1.5em" />
                          </span>
                          {profiledata?.university_degree ? (
                            <span>{`${profiledata?.university_degree} at ${profiledata?.university}`}</span>
                          ) : (
                            <span>{profiledata?.university}</span>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="col-12 col-md-6">
                    <ul className="user_popup_list">
                      {profiledata?.interested_in && (
                        <li className="user_popup_list_item">
                          <span>
                            <ImHappy size="1.5em" />
                          </span>
                          <span>{profiledata?.interested_in}</span>
                        </li>
                      )}
                      {profiledata?.classification && (
                        <li className="user_popup_list_item">
                          <span>
                            <BsStars size="1.5em" />
                          </span>
                          <span>{profiledata?.classification}</span>
                        </li>
                      )}
                      {profiledata?.organization && (
                        <li className="user_popup_list_item">
                          <span>
                            <BsFillBriefcaseFill size="1.5em" />
                          </span>
                          {profiledata?.organization_title ? (
                            <span>{`${profiledata?.organization_title} at ${profiledata?.organization}`}</span>
                          ) : (
                            <span>{profiledata?.organization}</span>
                          )}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="user_popup border-0">
            <div className="row">
              {nextbtnvisible ? (
                <React.Fragment>
                  <div className="col-12 col-md-6 pr-md-0">
                    {callScheduled ? (
                      <button
                        className="user_popup_schedulecall_btn disabled_btn"
                        data-id={profiledata?.id}
                        disabled={true}
                        style={{
                          backgroundColor: "transparent",
                          color: "green",
                          borderColor: "#ddd",
                        }}
                      >
                        <AiOutlineSchedule
                          size="1.8em"
                          style={{
                            color: "green !important",
                          }}
                        />{" "}
                        Scheduled
                      </button>
                    ) : (
                      <button
                        className="user_popup_schedulecall_btn"
                        onClick={() => {
                          setSchedulePopupVisible(!schedulePopupVisible);
                        }}
                        data-id={profiledata?.id}
                      >
                        <AiOutlineSchedule size="1.8em" /> Schedule a Call
                      </button>
                    )}
                  </div>
                  <div className="col-12 col-md-6 pl-md-0">
                    {userCredit > 0 ? (
                      <button
                        className="user_popup_schedulecall_btn next_profile"
                        onClick={() => {
                          NextProfileClickToParent();
                          callScheduledFunc(false);
                        }}
                      >
                        <AiOutlineUser size="1.8em" /> Next Profile
                      </button>
                    ) : (
                      <button
                        className="user_popup_schedulecall_btn next_profile btn-disabled"
                        // disabled={true}
                        onClick={handleShowOutofCreditWindow}
                      >
                        <AiOutlineUser size="1.8em" /> Next Profile
                      </button>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="col-12">
                    {callScheduled ? (
                      <button
                        className="user_popup_schedulecall_btn disabled_btn"
                        data-id={profiledata?.id}
                        disabled={true}
                        style={{
                          backgroundColor: "transparent",
                          color: "green",
                          borderColor: "#ddd",
                        }}
                      >
                        <AiOutlineSchedule
                          size="1.8em"
                          style={{
                            color: "green !important",
                          }}
                        />{" "}
                        Scheduled
                      </button>
                    ) : (
                      <button
                        className="user_popup_schedulecall_btn"
                        onClick={(e) => {
                          e.preventDefault();
                          setSchedulePopupVisible(!schedulePopupVisible);
                        }}
                        data-id={profiledata?.id}
                      >
                        <AiOutlineSchedule size="1.8em" /> Schedule a Call
                      </button>
                    )}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if(userCredit === 0 && showOutofCreditwindow) {
    userProfileUI = (
      <React.Fragment>
        <div className="col-12">
          <div
            className="user_popup border-bottom-0 text-center py-4  py-md-5 px-4 px-md-5"
            style={{ backgroundColor: "#212529" }}
          >
            <h2 className="text-danger mb-3 mb-md-4">
              You are all out of credits!
            </h2>
            <p className="text-white mb-3 mb-md-5 w-75 mx-auto">
              Each day you will receive more credits. If you would like more
              now, invite your friends to get additional credits.
            </p>

            <button
              className="btn btn-primary px-5 rounded-0"
              data-toggle="modal"
              data-target="#personalInviteModal"
            >
              Learn More
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  return (
    <div className={otherClassName ? otherClassName : ""}>
      <React.Fragment>
        <div className="user_popup_backdrop"></div>
        {profiledata?.message === "No more data available" &&
        profiledata !== "" ? (
          <NoMoreProfile
            sendDataToParentNoMoreData={sendDataToParentNoMoreData}
          />
        ) : (
          <React.Fragment>
            {!schedulePopupVisible ? (
              <div className="user_popup_container">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 text-center user_popup_notes">
                      {titleVisible && (
                        <h3 className="username">
                          {firstname}, Let's Get You Connected!
                        </h3>
                      )}
                      {nextbtnvisible && (
                        <React.Fragment>
                          <p className="creditinfo">
                            You have <b>{userCredit} credits</b> left.
                          </p>
                          <p className="note">
                            When you find someone you would like to connect
                            with, start by scheduling a call. To reveal a new
                            profile it will cost you 1 credit. Choose wisely :)
                          </p>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                  {userCredit >= 0 && (
                    <div className="row position-relative">
                      {loading && (
                        <div className="user_popup_loading">
                          <p>Loading...</p>
                        </div>
                      )}

                      {profiledata ? userProfileUI : userProfilePlaceholder}
                    </div>
                  )}
                  {showCancelButton ? (
                    <div className="row">
                      <div className="col-12 mt-2">
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            onClick={() => sendDatatoParent(!popupVisible)}
                            className="mx-2 user_popup_cancel"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <ScheduleCallPopup
                userid={profiledata.id}
                firstname={profiledata.first_name}
                lastname={profiledata.last_name}
                profile_picture={profiledata.profile_picture}
                email={profiledata.email || profiledata.user_email}
                popupvisible={schedulePopupVisible}
                sendDataToParentSchedule={sendDataToParentSchedule}
                callScheduled={callScheduledFunc}
                requestFromMyConnection={requestFromMyConnection}
                senderId={profiledata?.sender_id}
                senderTimeZone={profiledata?.sender_timeZone}
                durationOptions={
                  scheduleSettings?.durations || durationOptionList
                }
                communicationOptions={
                  scheduleSettings?.communications ||
                  communicationTypeOptionList
                }
                scheduleTimezone={
                  scheduleSettings?.timeZone?.value || defaultTimeZone
                }
                availabilityHour={
                  scheduleSettings?.availabilitywindow ||
                  defaultAvailabilityWindow
                }
              />
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
};

export default UserProfilePopup;
